import React, { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker;

const regime_list = [
  { "numero": "601", "descripcion": "General de Ley Personas Morales" },
  { "numero": "603", "descripcion": "Personas Morales con Fines no Lucrativos" },
  { "numero": "605", "descripcion": "Sueldos y Salarios e Ingresos Asimilados a Salarios" },
  { "numero": "606", "descripcion": "Arrendamiento" },
  { "numero": "608", "descripcion": "Demás ingresos" },
  { "numero": "609", "descripcion": "Consolidación" },
  { "numero": "610", "descripcion": "Residentes en el Extranjero sin Establecimiento Permanente en México" },
  { "numero": "611", "descripcion": "Ingresos por Dividendos (socios y accionistas)" },
  { "numero": "612", "descripcion": "Personas Físicas con Actividades Empresariales y Profesionales" },
  { "numero": "614", "descripcion": "Ingresos por intereses" },
  { "numero": "615", "descripcion": "Régimen de los ingresos por obtención de premios" },
  { "numero": "616", "descripcion": "Sin obligaciones fiscales" },
  { "numero": "620", "descripcion": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos" },
  { "numero": "621", "descripcion": "Incorporación Fiscal" },
  { "numero": "622", "descripcion": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras" },
  { "numero": "623", "descripcion": "Opcional para Grupos de Sociedades" },
  { "numero": "624", "descripcion": "Coordinados" },
  { "numero": "625", "descripcion": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas" },
  { "numero": "626", "descripcion": "Régimen Simplificado de Confianza" }
];

const PdfCF = ({ onExtractedData }) => {
  const [file, setFile] = useState(null);
  const pdfDocumentRef = useRef(null);

  useEffect(() => {
    if (file) {
      extractTextFromPDF(file);
    }
    return () => {
      cleanupPdfDocument();
    };
  }, [file]);

  const cleanupPdfDocument = () => {
    if (pdfDocumentRef.current) {
      pdfDocumentRef.current.destroy();
      pdfDocumentRef.current = null;
    }
  };

  const extractTextFromPDF = async (file) => {
    cleanupPdfDocument();

    const fileReader = new FileReader();
    fileReader.onload = async function () {
      const typedarray = new Uint8Array(this.result);
      try {
        const pdf = await pdfjsLib.getDocument(typedarray).promise;
        pdfDocumentRef.current = pdf;
        const numPages = pdf.numPages;
        let extractedText = '';

        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          textContent.items.forEach((item) => {
            extractedText += item.str + ' ';
          });
        }

        parseText(extractedText);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  const parseText = (text) => {
    const rfcMatch = text.match(/RFC:\s*([A-Z0-9]+)/);
    const rfc = rfcMatch ? rfcMatch[1] : '';

    const denominacionMatch = text.match(/Denominación\/Razón Social:\s*([\w\s]+)(?=\s\s)/);
    const denominacion = denominacionMatch ? denominacionMatch[1].trim() : '';

    const codigoPostalMatch = text.match(/Código Postal:\s*(\d+)/);
    const codigoPostal = codigoPostalMatch ? codigoPostalMatch[1] : '';

    const regimenesMatches = [...text.matchAll(/Régimen (.*?)(?=\s\d{2}\/\d{2}\/\d{4})/g)].map(match => match[1].trim());

    const matchedRegimes = [];

    for (const regimen of regimenesMatches) {
      for (const regime of regime_list) {
        if (regimen.toLowerCase().includes(regime.descripcion.toLowerCase())) {
          matchedRegimes.push({
            descripcion: regime.descripcion,
            numero: regime.numero
          });
        }
      }
    }

    const extractedInfo = {
      RFC: rfc,
      "Denominación/Razón Social": denominacion,
      "Código Postal": codigoPostal,
      Regímenes: matchedRegimes
    };

    console.log(extractedInfo);
    onExtractedData(extractedInfo);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <div>
      <p>Subir la cedula en formato pdf</p>
      <input type="file" onChange={handleFileChange} />
    </div>
  );
};

export default PdfCF;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Mock data for Uso de CFDI options
export const usoCFDIOptions = [
  { value: 'G01', label: 'G01 - Adquisición de mercancías' },
  { value: 'G02', label: 'G02 - Devoluciones, descuentos o bonificaciones' },
  { value: 'G03', label: 'G03 - Gastos en general' }, // This is the default option
  { value: 'S01', label: 'S01 - Sin efectos fiscales' }, // New option added
  { value: 'P01', label: 'P01 - Por definir' }
];

function UsoCFDIDropdown({ usoCFDI, setUsoCFDI }) {
  // Default value for G03
  const defaultOption = usoCFDIOptions.find(opt => opt.value === 'G03');

  const [selectedOption, setSelectedOption] = useState(defaultOption);

  useEffect(() => {
    if (usoCFDI) {
      const option = usoCFDIOptions.find(opt => opt.value === usoCFDI);
      setSelectedOption(option || defaultOption);
    }
  }, [usoCFDI]);

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setUsoCFDI(option.value); // Set only the value for usoCFDI
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused || state.hasValue ? 'blue' : provided.borderColor,
      marginBottom: '16px', // Add margin here to match the spacing
      '&:hover': {
        borderColor: state.isFocused || state.hasValue ? 'blue' : provided['&:hover'].borderColor,
      }
    })
  };

  return (
    <Select
      value={selectedOption}
      options={usoCFDIOptions}
      onChange={handleSelectChange}
      placeholder="Selecciona Uso de CFDI"
      isSearchable={true}
      styles={customStyles}
    />
  );
}

export default UsoCFDIDropdown;

import React, { useState, useEffect } from 'react';
import Select from 'react-select';

// Mock data based on the provided image
// In a real application, this would likely come from an API or other data source
export const regimenOptions = [
  { value: '601', label: '601 - General de Ley Personas Morales' },
  { value: '603', label: '603 - Personas Morales con Fines no Lucrativos' },
  { value: '605', label: '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios' },
  { value: '606', label: '606 - Arrendamiento' },
  { value: '607', label: '607 - Régimen de Enajenación o Adquisición de Bienes' },
  { value: '608', label: '608 - Demás ingresos' },
  { value: '609', label: '609 - Consolidación' },
  { value: '610', label: '610 - Residentes en el Extranjero sin Establecimiento Permanente en México' },
  { value: '611', label: '611 - Ingresos por Dividendos (socios y accionistas)' },
  { value: '612', label: '612 - Personas Físicas con Actividades Empresariales y Profesionales' },
  { value: '614', label: '614 - Ingresos por intereses' },
  { value: '615', label: '615 - Régimen de los ingresos por obtención de premios' },
  { value: '616', label: '616 - Sin obligaciones fiscales' },
  { value: '620', label: '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
  { value: '621', label: '621 - Incorporación Fiscal' },
  { value: '622', label: '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
  { value: '623', label: '623 - Opcional para Grupos de Sociedades' },
  { value: '624', label: '624 - Coordinados' },
  { value: '625', label: '625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas' },
  { value: '626', label: '626 - Régimen Simplificado de Confianza' },
  { value: '628', label: '628 - Hidrocarburos' },
  { value: '629', label: '629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
  { value: '630', label: '630 - Enajenación de acciones en bolsa de valores' }
];

function RegimenFiscalDropdown({ rf, setRF }) {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (rf) {
      const option = regimenOptions.find(opt => opt.value === rf.value);
      setSelectedOption(option || null);
    }
  }, [rf]);

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setRF(option);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused || state.hasValue ? 'green' : provided.borderColor,
      '&:hover': {
        borderColor: state.isFocused || state.hasValue ? 'green' : provided['&:hover'].borderColor,
      }
    })
  };

  return (
    <Select
      value={selectedOption}
      options={regimenOptions}
      onChange={handleSelectChange}
      placeholder="Selecciona Regimen Fiscal"
      isSearchable={true}
      styles={customStyles}
    />
  );
}

export default RegimenFiscalDropdown;

import React, { useState } from 'react';
import '../factura/Nombre.css'; // Reusing the styling from Nombre

function TotalInput({ TotalIn ,setEm}) {
  const [Total, setTotal] = useState('');
  const [isValid, setIsValid] = useState(false);
    
  const handleChange = (event) => {
    const newTotal = event.target.value;
    setTotal(newTotal);

    // Basic Total validation regex pattern
    const TotalRegex = /^\d+$/;
    //const TotalRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    setIsValid(TotalRegex.test(newTotal));
    setEm({ value: newTotal, isValid: TotalRegex.test(newTotal) });
  };

  return (
    <div className="form-group">
      <input
        type="Total"
        className={`form-control nombre-input ${isValid ? 'is-valid' : 'is-invalid'}`}
        placeholder="Total de la venta"
        value={Total}
        onChange={handleChange}
      />
      {!isValid && Total.length > 0 && (
        <div className="invalid-feedback">Ingrese el total de la venta sin centavos</div>
      )}
    </div>
  );
}

export default TotalInput;

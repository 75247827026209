import React, { useState,useContext, useEffect } from 'react';
import axios from 'axios';
import { DataContext } from '../../db/dataProvider';

import { useNavigate } from 'react-router-dom';
import TotalInput from './total';
import CodigoFacturacion from './codigo_facturacion';

//const axios = require('axios');

function TicketPage() {
  const { ticket, setTicket } = useContext(DataContext);
  const { branch } = useContext(DataContext); // Access branch data from context
  const [TotalVenta, setTotalVenta] = useState({ value: '0', isValid: false });
  const { franchise } = useContext(DataContext);


  const navigate = useNavigate();  // Use the useNavigate hook for navigation

  const [uuid, setUuid] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Redirect to home if no branch is selected
    if (!branch || !branch.endpoints) {
      navigate('/');
    }
  }, [branch, navigate]); 
  const verifyTicket = async (event) => {
    event.preventDefault();
    setLoading(true);
    setStatus('');

    try {
      //console.log("branchGet:"+branch.endpoints.get )
      const body = {
        total: TotalVenta.value,
        codigo_facturacion: ticket.codigo_facturacion,
      };
      const response = await axios.post(`${branch.endpoints.post}/verify/`, body);
      //console.log(`${branch.endpoints.post}/verify/`);
      //console.log("responseData:",response.data);
      // if then load factura page
       if(response.status === 200)
       {
        setTicket(response.data);
        console.log("este es el ticket:",ticket);
        // link to the factura page
        navigate('/factura');
         

       }
    } catch (response) {
      setStatus(`Status: ${JSON.stringify(response.response.data)}`);

      const responseData = response.response.data; // This is an object

      setStatus(`Error: ${responseData.error} `);
    
      console.log("este es el error:",response.status, response.message, response.response.data);
    }

    setLoading(false);
  };



  return (
    <div className="container card">
      <h1>Verificar el total del ticket</h1>
       <TotalInput TotalIn={TotalVenta} setEm={setTotalVenta}  />
       <h1>Verificar el Codigo de Facturación</h1>
      <CodigoFacturacion Codigo={ticket} setCodigo={setTicket} TotalIn={TotalVenta} />
    </div>
  );
}

export default TicketPage;

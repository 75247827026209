import React, { useState, useContext } from 'react';
import { DataContext } from '../../db/dataProvider';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

// Import sucursales.json correctly assuming the actual path is under 'controllers'
import sucursalesData from '../../controllers/sucursales.json';

function SucursalSelectPage() {
  const { setBranch } = useContext(DataContext);
  const { setFranchise } = useContext(DataContext);
  const navigate = useNavigate();

  // Function to handle branch selection
  const handleBranchSelect = (branch) => {
    setBranch(branch);
    navigate('/ticket');
  };

  // Use the 'sucursales' array from the imported JSON data
  const branches = sucursalesData.sucursales;
  const franchise = sucursalesData.franchise;
  //console.log("franchise:",franchise);
  //console.log("branches:",branches);

  
  setFranchise(franchise);


  return (
    <Container className="p-4">
    {/* Header with logo and franchise name */}
    <header className="text-center mb-4">
    <h1 className="m-0">{franchise.name}</h1>
    

      <img src={franchise.logo} alt={franchise.name} className="mb-3" style={{ maxWidth: '150px' }} />
      <h1 className="m-0">Seleccione La Sucursal</h1>
      <h4 className="mt-2 text-muted">Por favor seleccione la sucursal donde realizó su consumo</h4>
    </header>
    
    {/* Branch selection cards */}
    <Row xs={1} md={3} className="g-3">
      {branches.map((branch, idx) => (
        <Col key={idx}>
          <Card 
            className="h-100 shadow-sm" // Added shadow for depth
            onClick={() => handleBranchSelect(branch)}
            style={{ cursor: 'pointer', borderRadius: '8px' }} // Rounded corners
          >
            <Card.Body>
              <Card.Title>{branch.nombre}</Card.Title>
              <Card.Text>
                {branch.direccion}
              </Card.Text>
              {/* Additional branch details can be included here */}
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  </Container>
  );
}

export default SucursalSelectPage;

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import axios from 'axios'; // Ensure axios is imported

import '../factura/Nombre.css'; // Reusing the styling from Nombre
import { DataContext } from '../../db/dataProvider';


function CodigoFacturacion({ Codigo, setCodigo, TotalIn }) {
  const [codigo_facturacion, setCodigo_facturacion] = useState('');
  const [isValid, setIsValid] = useState(false);
  const { ticket, setTicket } = useContext(DataContext);
  const [errorMessage, setErrorMessage] = useState(''); // State to store the error message
  const { branch } = useContext(DataContext); // Access branch data from context
  const [tot] = useState(TotalIn);
  const navigate = useNavigate(); // Correctly obtain the navigate function.

  useEffect(() => {
//    setCodigo_facturacion(TotalIn.value);
//    console.log("TotalIn prop:", TotalIn);
//console.log("ticket:",ticket);
  }, [TotalIn, ticket]);

  const handleChange = (event) => {
    setCodigo_facturacion(event.target.value.toUpperCase());
    const CodigoRegex = /^[0-9A-F]{5,9}$/;
    const valid = CodigoRegex.test(event.target.value.toUpperCase());
    setIsValid(valid);
    setCodigo({ value: event.target.value, isValid: valid });
    console.log("ticket->",ticket);
    if (!valid) {
      // Set a more specific error message
      setErrorMessage('Código debe tener entre 6 y 9 caracteres hexadecimales.');
    } else {
      setErrorMessage('');
    }
  };
  
  // Added for button click to manually validate
  const handleValidationClick = () => {
    

if (!branch) {
  navigate('/');
}
   // setCodigo_facturacion(codigo_facturacion); // This will trigger the useEffect to validate the code
    //console.log("branch.endpoints.get:",branch.endpoints.get);
let body = {codigo_facturacion:codigo_facturacion,total:TotalIn.value};
console.log("body:",body);
    axios.post(`${branch.endpoints.post}/verify/`, body)
      .then((response) => {
        console.log("response:",response);
        if (response.status === 200) {
          setTicket(response.data);
          setCodigo_facturacion(codigo_facturacion);
          setIsValid(true);
          const ventas = response.data.ventas;
          const firstVenta = ventas.length > 0 ? ventas[0] : null;
  
          if (firstVenta && firstVenta.factura) {
            setTicket(firstVenta);
            console.log("Este es el ticket:", firstVenta);
            navigate('/factura');
            return;
          }


          navigate('/factura');
          //navigate('/factura');
        }
      })
      .catch((error) => {
        console.error('Error validating code:', error.response.data.message);
        setErrorMessage(error.response?.data?.message || 'Error al validar el código.');
        setIsValid(false);
      });


  };
  
  return (
    <div className="form-group" style={{ display: 'flex' }}>
    <input
      type="text"
      className={`form-control nombre-input ${isValid ? 'is-valid' : 'is-invalid'}`}
      placeholder="Codigo de Facturación"
      value={codigo_facturacion}
      onChange={handleChange}
      maxLength="9"
      style={{ width: '75%' }}
    />
    <button 
      type="button" 
      className="btn btn-primary" 
      style={{ width: '25%' }} 
      onClick={handleValidationClick}
    >
      Validar
    </button>
    {!isValid && codigo_facturacion.length > 0 && (
      <div className="invalid-feedback">{errorMessage}</div> // Display the specific error message
    )}
  </div>
  );
}

export default CodigoFacturacion;

// In ./db/dataProvider.js
import React, { createContext, useState } from 'react';

export const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  // Initialize state for each object
  const [emisor, setEmisor] = useState(null);
  const [receptor, setReceptor] = useState(null);
  const [ticket, setTicket] = useState(null);
  const [factura, setFactura] = useState(null);
  const [branch, setBranch] = useState(null); // Add this if you plan to manage branch state
  const [totalVenta, setTotalVenta] = useState(null);
  const [franchise, setFranchise] = useState(null);
  

  const contextValue = {
    emisor, setEmisor,
    receptor, setReceptor,
    ticket, setTicket,
    factura, setFactura,
    branch, setBranch, // Include this in context
    totalVenta,setTotalVenta,
    franchise, setFranchise,
  };

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

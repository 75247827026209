// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nombre-input {
    border-radius: 0.25rem; /* Adjust radius to your preference */
    text-align: center;
  }
  
  .nombre-input.is-valid {
    border-color: green !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 128, 0, 0.25);
  }
  
  .nombre-input.is-invalid {
    border-color: red !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/factura/Nombre.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB,EAAE,qCAAqC;IAC7D,kBAAkB;EACpB;;EAEA;IACE,8BAA8B;IAC9B,8CAA8C;EAChD;;EAEA;IACE,4BAA4B;IAC5B,8CAA8C;EAChD","sourcesContent":[".nombre-input {\n    border-radius: 0.25rem; /* Adjust radius to your preference */\n    text-align: center;\n  }\n  \n  .nombre-input.is-valid {\n    border-color: green !important;\n    box-shadow: 0 0 0 0.2rem rgba(0, 128, 0, 0.25);\n  }\n  \n  .nombre-input.is-invalid {\n    border-color: red !important;\n    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import './Nombre.css'; // Reusing the styling from Nombre

function DomicilioFiscal({ df, setDF }) {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setValue(df.value || '');
    setIsValid(df.isValid || false);
  }, [df]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    // Zip code validation: exactly 5 digits
    const zipCodeRegex = /^\d{5}$/;
    const isValidInput = zipCodeRegex.test(newValue);

    setIsValid(isValidInput);
    setDF({ value: newValue, isValid: isValidInput });
  };

  return (
    <div className="form-group">
      <input
        type="text"
        className={`form-control nombre-input ${isValid ? 'is-valid' : 'is-invalid'}`}
        placeholder="Domicilio Fiscal (CP)"
        value={value}
        onChange={handleChange}
        maxLength="5" // Ensures that no more than 5 characters can be input
      />
    </div>
  );
}

export default DomicilioFiscal;

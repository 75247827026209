import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from '../../db/dataProvider';
import './resultado.css';

function ResultadoPage() {
  const location = useLocation();
  const [generationStatus, setGenerationStatus] = useState('Generando Factura...');
  const [pdfUrl, setPdfUrl] = useState('');
  const [xmlUrl, setXmlUrl] = useState('');
  const [counter, setCounter] = useState(8); // Initialize counter to 8 seconds
  const { receptor, ticket, branch } = useContext(DataContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      console.error('location.state is null');
      setGenerationStatus('Error: No data provided.');
      return;
    }

    function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const base64ContentArray = b64Data.split(',');
      if (base64ContentArray.length > 1) {
        b64Data = base64ContentArray[base64ContentArray.length - 1];
      }

      b64Data = b64Data.replace(/[^A-Za-z0-9\+\/]/g, '');
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    const fetchData = async () => {
      const requestData = {
        ...location.state,
        receptor,
        ticket,
        branch,
      };

      try {
        const response = await axios.post(branch.endpoints.timbrar, requestData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response && response.data) {
          setGenerationStatus('Timbrando, procesando respuesta... ');

          if (response.data.pdf) {
            const pdfBlob = b64toBlob(response.data.pdf, 'application/pdf');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfUrl);
          }

          if (response.data.xml) {
            const xmlBlob = new Blob([response.data.xml], { type: 'application/xml' });
            const xmlUrl = URL.createObjectURL(xmlBlob);
            setXmlUrl(xmlUrl);
          }
          setGenerationStatus(response.data.message);
        } else {
          console.error('Response or response.data is undefined');
          setGenerationStatus('Error: Unexpected response format.');
        }
      } catch (error) {
        console.error('Error fetching data:', error.response?.data?.message || error.message);
        setGenerationStatus('Error tratando de timbrar la factura -> ' + error.response?.data?.message);

        // Start counter for automatic navigation
        const interval = setInterval(() => {
          setCounter((prevCounter) => {
            if (prevCounter <= 1) {
              clearInterval(interval);
              navigate('/factura', { state: location.state });
            }
            return prevCounter - 1;
          });
        }, 1000);
      }
    };

    fetchData();
  }, [location.state, receptor, ticket, branch, navigate]);

  const cancelNavigation = () => {
    setCounter(null); // Stop the counter
  };

  if (!location.state) {
    return <div className="resultado-page-error">Acceso inválido o sesión expirada. Por favor, navegue desde la página correcta.</div>;
  }

  const formatData = (data) => {
    const entries = Object.entries(data);
    return entries.map(([key, value]) => (
      <div key={key} className="resultado-page-info-item">
        <strong>{key}:</strong> {value}
      </div>
    ));
  };

  return (
    <div className="resultado-page-container">
      <h1 className="resultado-page-title">Resultado de la Operación</h1>
      <p className="resultado-page-status">
        Estado: <strong>{generationStatus}</strong>
      </p>
      {counter !== null && (
        <p className="resultado-page-counter">
          Regresando a la página anterior en {counter} segundos...
        </p>
      )}
      <button onClick={cancelNavigation} className="resultado-page-cancel-button">
        Cancelar
      </button>
      <div className="resultado-page-download">
        {pdfUrl && (
          <a href={pdfUrl} download className="resultado-page-download-link pdf-link">
            Descargar PDF
          </a>
        )}
        {xmlUrl && (
          <a href={xmlUrl} download className="resultado-page-download-link xml-link">
            Descargar XML
          </a>
        )}
      </div>
      <div className="resultado-page-info">
        <h2>Información Capturada:</h2>
        {formatData(location.state)}
      </div>
    </div>
  );
}

export default ResultadoPage;

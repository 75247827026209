import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from '../../db/dataProvider';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection


function ConsultaPage() {
  const location = useLocation();
  const [generationStatus, setGenerationStatus] = useState('Generando Factura...');
  const [pdfUrl, setPdfUrl] = useState('');
  const [xmlUrl, setXmlUrl] = useState('');
  const { receptor, ticket, branch } = useContext(DataContext);
  const navigate = useNavigate(); // Correctly obtain the navigate function.


  useEffect(() => {
    if (!location.state) {
      console.error('location.state is null');
      setGenerationStatus('Error: No data provided.');
      return;
    }
    function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      // Remove the data URL scheme if it's present and extract only the base64 string
      const base64ContentArray = b64Data.split(',');
      if (base64ContentArray.length > 1) {
        // Assume the actual base64 string is at the last position after split
        b64Data = base64ContentArray[base64ContentArray.length - 1];
      }
    
      // atob() can handle minor misalignments in padding by itself, but just in case:
      b64Data = b64Data.replace(/[^A-Za-z0-9\+\/]/g, '');
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
    
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
    
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }
    
    
    const fetchData = async () => {
      const requestData = {
        ...location.state,
        receptor,
        ticket,
        branch,
      };

      try {
        const response = await axios.post(branch.endpoints.timbrar, requestData, {
          headers: {
            'Content-Type': 'application/json',
          },
          
        })
        .then((response) => {
          if (response && response.data) {
              setGenerationStatus('Timbrando, procesando respuesta... ');

              console.log("response:",response);
              //generate the pdf file from response.data.pdf
              if (response.data.pdf) {
                const pdfBlob = b64toBlob(response.data.pdf, 'application/pdf');
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPdfUrl(pdfUrl);

              }
              if (response.data.xml) {
                const xmlBlob = new Blob([response.data.xml], { type: 'application/xml' });
                const xmlUrl = URL.createObjectURL(xmlBlob);
                setXmlUrl(xmlUrl);
              }
              setGenerationStatus('Factura generada correctamente.');
            } else {
              // Handle the case where response or response.data is undefined
              console.error('Response or response.data is undefined');
              setGenerationStatus('Error: Unexpected response format.');

            }
        })
        .catch((error) => {
          console.log("error:",error);
          console.log("error.response:",error.response.data.message);
          setGenerationStatus(error.response.data.message);

          //wait 5000 ms and then navigate to the factura page
         /* setTimeout(() => {
            navigate('/factura');

          }, 10000);
*/

          return error;
        });
             
      } catch (error) {
        console.error('Error fetching data:', error.response.data.message);
        setGenerationStatus('Error fetching data. Please try again.');
      }
    };


    
    fetchData();
  }, [location.state, receptor, ticket, branch]);

  if (!location.state) {
    return <div>Invalid access or session expired. Please navigate from the correct page.</div>;
  }

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', maxWidth: '600px', margin: 'auto' }}>
      <h1 style={{ color: '#333', textAlign: 'center' }}>Resultado de la Operación</h1>
      <p style={{ fontSize: '16px', color: '#555', textAlign: 'center' }}>
        Estado: <strong>{generationStatus}</strong>
      </p>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {pdfUrl && (
          <a
            href={pdfUrl}
            download
            style={{
              display: 'inline-block',
              margin: '10px',
              padding: '10px 20px',
              background: '#007bff',
              color: '#fff',
              textDecoration: 'none',
              borderRadius: '5px',
            }}
          >
            Descargar PDF
          </a>
        )}
        {xmlUrl && (
          <a
            href={xmlUrl}
            download
            style={{
              display: 'inline-block',
              margin: '10px',
              padding: '10px 20px',
              background: '#28a745',
              color: '#fff',
              textDecoration: 'none',
              borderRadius: '5px',
            }}
          >
            Descargar XML
          </a>
        )}
      </div>
    </div>
  );
  
}
export default ConsultaPage;


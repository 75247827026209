// In App.js
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import TicketPage from './pages/ticket/ticket';
import FacturaPage from './pages/factura/factura';
import ResultadoPage from './pages/resultado/resultado';
import SucursalSelectPage from './pages/sucursal/sucursal';
import CancelarFactura from './pages/resultado/cancelarFactura';
import ConsultaPage from './pages/consulta/consulta'; 
import { DataProvider } from './db/dataProvider'; // Ensure this import is correct


function App() {
  return (
    <DataProvider>
      <Router>
        <Routes>
          <Route path="/sucursal" element={<SucursalSelectPage />} />
          <Route path="/ticket" element={<TicketPage />} />
          <Route path="/factura" element={<FacturaPage />} />
          <Route path="/resultado" element={<ResultadoPage />} />
          <Route path="/consulta" element={<ConsultaPage />} />
          <Route path="/cancelar" element={<CancelarFactura />} />
          
          {/* Default route */}
          <Route path="*" element={<SucursalSelectPage />} />
        </Routes>
      </Router>
    </DataProvider>
  );
}

export default App;

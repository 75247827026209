import React, { useState, useEffect } from 'react';
import './Nombre.css'; // Reusing the styling from Nombre

function RFCComp({ rfc, setRFC }) {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setValue(rfc.value || '');
    setIsValid(rfc.isValid || false);
  }, [rfc]);

  const handleChange = (event) => {
    const newValue = event.target.value.toUpperCase();
    setValue(newValue);

    // RFC validation (basic structure): 4 letters followed by 6 numbers followed by 3 alphanumeric characters.
    const rfcRegex = /^[A-ZÑ&]{3,4}\d{6}(?:[A-Z\d]{3})?$/;
    const isValidInput = rfcRegex.test(newValue);

    setIsValid(isValidInput);
    setRFC({ value: newValue, isValid: isValidInput });
  };

  return (
    <div className="form-group">
      <input
        type="text"
        className={`form-control nombre-input ${isValid ? 'is-valid' : 'is-invalid'}`}
        placeholder="RFC"
        value={value}
        onChange={handleChange}
        style={{ textTransform: 'uppercase' }}
      />
    </div>
  );
}

export default RFCComp;

import React, { useState } from 'react';
import './Nombre.css'; // Reusing the styling from Nombre

function EmailInput({ emailIn ,setEm}) {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
    //console.log("email:",email,"isValid:",isValid);
  const handleChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Basic email validation regex pattern
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    setIsValid(emailRegex.test(newEmail));
    setEm({ value: newEmail, isValid: emailRegex.test(newEmail) });
  };

  return (
    <div className="form-group">
      <input
        type="email"
        className={`form-control nombre-input ${isValid ? 'is-valid' : 'is-invalid'}`}
        placeholder="Correo Electrónico"
        value={email}
        onChange={handleChange}
      />
      {!isValid && email.length > 0 && (
        <div className="invalid-feedback">Ingrese una direccion de correo correcta.</div>
      )}
    </div>
  );
}

export default EmailInput;
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Modal from 'react-modal'; // Ensure react-modal is installed: npm install react-modal

// Mock data for payment options
export const paymentOptions = [
  { value: '01', label: '01 - Efectivo' },
  { value: '02', label: '02 - Cheque nominativo' },
  { value: '03', label: '03 - Transferencia electrónica de fondos' },
  { value: '04', label: '04 - Tarjeta de crédito' },
  // Add other payment options as needed
];

// Main component
function FormaDePago({ defaultPayment, updatePayment }) {
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Fetch the default payment method when the component loads
  useEffect(() => {
    if (defaultPayment) {
      const option = paymentOptions.find(opt => opt.value === defaultPayment);
      setSelectedPayment(option || null);
    }
  }, [defaultPayment]);

  // Open and close modal functions
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  // Handle selection change in dropdown
  const handleSelectChange = (option) => {
    setSelectedPayment(option);
  };

  // Handle confirmation in the modal
  const handleConfirmUpdate = () => {
    if (selectedPayment) {
      updatePayment(selectedPayment.value); // Update in the database
    }
    closeModal();
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: 'green',
      marginBottom: '16px',
    })
  };

  return (
    <div>
      <Select
        value={selectedPayment}
        options={paymentOptions}
        onChange={handleSelectChange}
        placeholder="Selecciona Forma de Pago"
        isSearchable={true}
        styles={customStyles}
      />
      <button onClick={openModal} className="btn btn-secondary">Corregir</button>

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Confirmar Corrección">
        <h2>Confirmar Corrección de Forma de Pago</h2>
        <p>¿Estás seguro de que quieres actualizar la forma de pago?</p>
        <button onClick={handleConfirmUpdate} className="btn btn-primary">Confirmar</button>
        <button onClick={closeModal} className="btn btn-secondary">Cancelar</button>
      </Modal>
    </div>
  );
}

export default FormaDePago;

import React, { useState } from 'react';
import './Nombre.css'; // Reusing the styling from Nombre

function Telefono() {
  const [telefono, setTelefono] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleChange = (event) => {
    const newTelefono = event.target.value;
    setTelefono(newTelefono);

    // Telephone number validation: exactly 10 digits
    const telefonoRegex = /^\d{10}$/;
    setIsValid(telefonoRegex.test(newTelefono));
  };

  return (
    <div className="form-group">
      <input
        type="tel"
        className={`form-control nombre-input ${isValid ? 'is-valid' : 'is-invalid'}`}
        placeholder="Número de teléfono"
        value={telefono}
        onChange={handleChange}
        maxLength="10" // Ensures that no more than 10 characters can be input
      />
      {!isValid && telefono.length > 0 && (
        <div className="invalid-feedback">Ingrese un número de teléfono válido de 10 dígitos.</div>
      )}
    </div>
  );
}

export default Telefono;
